<template>
  <div class="my-footer">
    <div class="my-footer-container">
      <b-row style="height:100%">
        <b-col sm="12" md="6" lg="4" class="d-flex justify-content-center">
          <div style="width : 320px;height:100%;" class="pt-2 px-4">
            <p class="footerTextBig">{{ $t("Информация") }}</p>
            <p class="mt-4 footerText mb-1">
              {{ $t("OzResXTV") }}
            </p>
            <p class="m-0 p-0 footerText mb-1">Об организации</p>
            <p class="m-0 p-0 footerText mb-1">Законодательства</p>
            <p class="m-0 p-0 footerText mb-1">Вопросы и ответы</p>
          </div>
        </b-col>
        <b-col md="6" lg="4" sm="12" class="d-flex justify-content-center">
          <div style="width : 280px;height:100%;" class="pt-2 px-4">
            <p class="footerTextBig">{{ $t("Contact") }}</p>
            <div class="mt-4">
              <b-row>
                <b-col>
                  <img
                    class="mr-3"
                    src="@/assets/pictures/Iconfeather-phone.png"
                  />
                  <p class="footerText" style="display:contents;">
                    +998 71 241 01 86
                  </p>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mt-2">
                  <img class="mr-3" src="@/assets/pictures/Контур517.png" />
                  <p class="footerText" style="display:contents;">
                    info@xtv.uz
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-2">
                  <img class="mr-3" src="@/assets/pictures/Контур 478.png" />
                  <p class="footerText" style="display:contents;">
                    Ташкент, ул. Ташкент. 32
                  </p>
                </b-col>
              </b-row>
            </div>
            <div class="mt-3">
              <div
                class="social-set d-flex justify-content-between align-items-center"
              >
                <div>
                  <a target="_blank" href="https://www.facebook.com/uzedu/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="Facebook_Button"
                        data-name="Facebook Button"
                        transform="translate(0)"
                      >
                        <path
                          id="Icon_metro-facebook"
                          data-name="Icon metro-facebook"
                          d="M22.571,1.928h-16a4,4,0,0,0-4,4v16a4,4,0,0,0,4,4h8v-10.5h-3v-3h3v-2.25a3.75,3.75,0,0,1,3.75-3.75h3.75v3h-3.75a.75.75,0,0,0-.75.75v2.25H21.7l-.75,3H17.571v10.5h5a4,4,0,0,0,4-4v-16a4,4,0,0,0-4-4Z"
                          transform="translate(-2.571 -1.928)"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
                <div class="footerIcon">
                  <a target="_blank" href="https://www.instagram.com/uzedu.uz/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="Instagram_Button"
                        data-name="Instagram Button"
                        transform="translate(0.5)"
                      >
                        <path
                          id="Icon_awesome-instagram"
                          data-name="Icon awesome-instagram"
                          d="M12,8.084a6.153,6.153,0,1,0,6.152,6.153A6.143,6.143,0,0,0,12,8.084Zm0,10.154a4,4,0,1,1,4-4A4.007,4.007,0,0,1,12,18.238ZM19.836,7.833A1.435,1.435,0,1,1,18.4,6.4,1.432,1.432,0,0,1,19.836,7.833ZM23.91,9.289a7.1,7.1,0,0,0-1.938-5.029,7.147,7.147,0,0,0-5.028-1.939c-1.981-.112-7.919-.112-9.9,0A7.137,7.137,0,0,0,2.017,4.255,7.127,7.127,0,0,0,.079,9.284c-.112,1.981-.112,7.921,0,9.9a7.1,7.1,0,0,0,1.938,5.029,7.156,7.156,0,0,0,5.028,1.939c1.981.112,7.919.112,9.9,0a7.1,7.1,0,0,0,5.028-1.939,7.15,7.15,0,0,0,1.938-5.029c.112-1.981.112-7.915,0-9.9ZM21.351,21.312a4.05,4.05,0,0,1-2.281,2.281c-1.579.627-5.327.482-7.073.482s-5.5.139-7.073-.482a4.05,4.05,0,0,1-2.281-2.281c-.626-1.58-.482-5.329-.482-7.074s-.139-5.5.482-7.074A4.05,4.05,0,0,1,4.925,4.882C6.5,4.255,10.252,4.4,12,4.4s5.5-.139,7.073.482a4.05,4.05,0,0,1,2.281,2.281c.626,1.58.482,5.329.482,7.074S21.977,19.738,21.351,21.312Z"
                          transform="translate(-0.495 -2.238)"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
                <div class="footerIcon">
                  <a target="_blank" href="https://t.me/uzedu/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="Telegram_Button"
                        data-name="Telegram Button"
                        transform="translate(0)"
                      >
                        <path
                          id="Icon_awesome-telegram"
                          data-name="Icon awesome-telegram"
                          d="M12,.562a12,12,0,1,0,12,12A12,12,0,0,0,12,.562Zm5.894,8.221-1.969,9.281c-.145.658-.537.818-1.084.508l-3-2.211-1.447,1.394a.757.757,0,0,1-.6.3L10,15l5.56-5.023c.242-.213-.053-.334-.373-.121L8.318,14.179l-2.961-.924c-.644-.2-.658-.644.135-.953L17.061,7.84c.537-.194,1.006.131.832.944Z"
                          transform="translate(0 -0.562)"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col sm="12" md="6" lg="4" class="d-flex justify-content-center">
          <div style="width : 320px;height:100%;" class="pt-2 px-4">
            <p class="footerTextBig">{{ $t("work_day") }}</p>
            <div class="mt-4">
              <div class="d-flex">
                <div class="mb-2 mr-2">
                  <b-row>
                    <b-col>
                      <p class="footerText m-0 p-0">
                        {{ $t("Du") }} — {{ $t("Ju") }} :
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p class="footerTextColor m-0 p-0">
                        {{ $t("9:00 — 18:00") }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class="d-flex">
                <div class="mb-2 mr-2">
                  <b-row>
                    <b-col>
                      <p class="footerText m-0 p-0">
                        {{ $t("Sh") }} — {{ $t("Ya") }} :
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p class="footerTextColor m-0 p-0">
                        {{ $t("close") }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class="d-flex">
                <div class="mb-2 mr-2">
                  <b-row>
                    <b-col>
                      <p class="footerText m-0 p-0">{{ $t("lunch") }} :</p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p class="footerTextColor m-0 p-0" style="width:150px">
                        {{ $t("13:00 — 14:00") }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.face {
  margin-top: 22%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.face .inst {
  list-style: none;
}

.face .inst a {
  width: 30px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  line-height: 20px;
  font-size: 25px;
  margin: 0 8px;
  display: block;
  border-radius: 10%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}

.face .inst a .icon {
  position: relative;
  color: #262626;
  transition: 0.5s;
  z-index: 3;
}

.face .inst a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

.face .inst a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: 0.5s;
  z-index: 2;
}

.face .inst a:hover:before {
  top: 0;
}

.face .inst:nth-child(1) a:before {
  background: #3b5999;
}

.face .inst:nth-child(2) a:before {
  background: #55acee;
}

.face .inst:nth-child(3) a:before {
  background: #0077b5;
}

.face .inst:nth-child(4) a:before {
  background: #dd4b39;
}
</style>
