<template>
  <b-overlay>
    <!-- <header>
      <Navbar1 />
    </header> -->
    <my-navbar></my-navbar>
    <!-- <vue-navigation-bar :options="navbarOptions" /> -->
    <!-- <b-navbar toggleable="lg" type="dark" variant="info ">
      <b-container>
        <b-navbar-brand href="#"> </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown text="O'zbekcha" class="lang-dropdown" right>
              <b-dropdown-item href="#">EN</b-dropdown-item>
              <b-dropdown-item href="#">ES</b-dropdown-item>
              <b-dropdown-item href="#">RU</b-dropdown-item>
              <b-dropdown-item href="#">FA</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar> -->
    <div>
      <router-view></router-view>
    </div>
    <div>
      <my-footer></my-footer>
    </div>
  </b-overlay>
</template>

<script>
import myNavbar from "../layout/navbar";
// import Navbar1 from "../layout/Navbar1";
import myFooter from "../layout/footer";
export default {
  components: { myFooter, myNavbar },
  data() {
    return {};
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
header {
  width: 100vw;
  background-color: #222;
  padding: 15px;
}
</style>
