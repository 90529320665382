<template>
  <div>
    <nav class="my-navbar w-100" style="border-bottom : 2px solid #eee">
      <div class="my-container d-flex justify-content-between">
        <div
          class="align-items-center justify-content-between float-left my-hamb"
          style="height : 100%;cursor:pointer"
        >
          <b-icon v-b-toggle.sidebar-1 icon="list" scale="2.5"></b-icon>
        </div>
        <div
          class="my-logo d-flex align-items-center justify-content-between"
          style="width : 220px !important"
        >
          <div>
            <img
              width="70"
              class="d-block mt-auto mb-auto"
              src="@/assets/xtv.png"
              alt=""
            />
          </div>
          <div class="ml-3 xtv pt-3">
            <p style="width:500px !important">{{ $t("XTV") }}</p>
          </div>
        </div>
        <div class="my-menu">
          <ul>
            <!-- <li>
              <a> Rasmiy hujjatlar</a>
            </li> -->
            <!-- <li>
              <a class="navbarTextColor"> {{ $t("web_info") }} </a>
            </li>
            <li>
              <a class="navbarTextColor"> {{ $t("nashrlar") }} </a>
            </li>
            <li>
              <a class="navbarTextColor"> {{ $t("info") }} </a>
            </li> -->
            <!-- <li>
              <a class="navbarTextColor"> Acts in English </a>
            </li> -->
          </ul>
        </div>
        <!-- <div class="my-language">
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            right
          >
            <template #button-content>
              <b-button variant="inherit" class="my-button"
                ><b-icon scale="0.8" icon="globe"></b-icon>
                {{ $t("lang") }}
                <b-icon scale="0.6" icon="chevron-down"></b-icon>
              </b-button>
            </template>
            <b-dropdown-item @click="changeLanguage('ru')">
              O'zbek tili</b-dropdown-item
            >
            <b-dropdown-item @click="changeLanguage('uz_cyrl')">
              Ўзбек тили</b-dropdown-item
            >
            <b-dropdown-item @click="changeLanguage('uz_latn')">
              Русский язык</b-dropdown-item
            >
          </b-dropdown>
        </div> -->
        <div class="my-language">
          <b-dropdown
            class="my-dropdown d-inline-block"
            size="lg"
            split-variant="info"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            right
          >
            <template #button-content>
              <b-button class="btn" variant="inherit"
                ><b-icon scale="0.8" icon="globe"></b-icon>
                {{ $t("lang") }}
                <b-icon scale="0.6" icon="chevron-down"></b-icon>
              </b-button>
            </template>

            <b-dropdown-item
              @click="changeLanguage('uz_latn')"
              :class="{ active: lang === 'uz_latn' }"
            >
              Русский язык
            </b-dropdown-item>
            <b-dropdown-item
              @click="changeLanguage('uz_cyrl')"
              :class="{ active: lang === 'uz_cyrl' }"
            >
              Ўзбек тили</b-dropdown-item
            >
            <b-dropdown-item
              @click="changeLanguage('ru')"
              :class="{ active: lang === 'ru' }"
            >
              O'zbek tili
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <b-sidebar backdrop id="sidebar-1" title="Menu" shadow>
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>
              <b-icon icon="building" class="mr-2"> </b-icon> Детский сад
            </span>
            <!-- <b-badge variant="primary" pill>14</b-badge> -->
            <b-icon icon="chevron-right"></b-icon>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span> <b-icon icon="building" class="mr-2"> </b-icon> Школа </span>
            <!-- <b-badge variant="primary" pill>2</b-badge> -->
            <b-icon icon="chevron-right"></b-icon>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span> <b-icon icon="shop" class="mr-2"> </b-icon> Колледж </span>
            <!-- <b-badge variant="primary" pill>1</b-badge> -->
            <b-icon icon="chevron-right"></b-icon>
          </b-list-group-item>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>
              <b-icon icon="shop" class="mr-2"> </b-icon> Университет
            </span>
            <!-- <b-badge variant="primary" pill>14</b-badge> -->
            <b-icon icon="chevron-right"></b-icon>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span>
              <b-icon icon="box" class="mr-2"> </b-icon> Справочники
            </span>
            <!-- <b-badge variant="primary" pill>2</b-badge> -->
            <b-icon icon="chevron-right"></b-icon>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <span> <b-icon icon="box" class="mr-2"> </b-icon> Сервисы </span>
            <!-- <b-badge variant="primary" pill>1</b-badge> -->
            <b-icon icon="chevron-right"></b-icon>
          </b-list-group-item>
        </b-list-group>
      </b-sidebar>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: "",
    };
  },
  created() {
    this.lang = localStorage.getItem("lang");
  },
  methods: {
    changeLanguage(lang) {
      this.lang = lang;
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
  },
};
</script>

<style></style>
